<template>
  <div>
    <div class="solgan">
      <div class="solgan-left">
        <img src="@/assets/img/home/logo_white.png" alt="" />
      </div>
      <div class="solgan-right">
        <div class="title">Contact Us</div>
        <img src="@/assets/img/contact/contact.png" alt="" />
        <a class="contact-email" href="mailto:contact@admarketing.io"
          >contact@admarketing.io</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.solgan {
  width: 100%;
  height: calc(100vh);
  position: relative;
  overflow: auto;
  background: url(../../../assets/img/contact/bg.png);
  background-size: 100% 100%;
  &-left {
    width: 7.15%;
    height: 100vh;
    background: #121416;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 40px;
      height: auto;
      margin: 28px 0;
    }
  }
  &-right {
    margin-left: 7.15%;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    padding-top: 130px;
    box-sizing: border-box;
    .title {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 60px;
      text-transform: capitalize;
      color: #121416;
    }
    img {
      width: 256px;
      height: auto;
      margin: 96px 0;
    }
    .contact-email {
      font-family: "Outfit-Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      text-align: center;
      text-decoration-line: underline;
      color: #121416;
      cursor: pointer;
    }
    a {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 799px) {
  .solgan {
    background: url(../../../assets/img/contact/bg_mobile.png);
    background-size: 100% 100%;
    height: calc(100vh - 40px);
    &-left {
      display: none;
    }
    &-right {
      margin-left: 0px;
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: column;
      height: calc(100vh - 40px);
      overflow: auto;
      padding-top: 124px;
      box-sizing: border-box;
      .title {
        font-size: 32px;
        line-height: 48px;
      }
      img {
        width: 192px;
        height: auto;
        margin: 83px 0 30px;
      }
    }
  }
}
</style>