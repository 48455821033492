<template>
  <div style="height: 100%; width: 100%">
    <router-view></router-view>
  </div>
</template>

<script>
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    // Footer,
    // Nav,
    // Contactus,
  },
  data() {
    return {
      closeShow: false,
    };
  },
  methods: {
    close(e) {
      this.closeShow = e;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>