<template>
  <div>
    <div class="introduce">
      <div class="home-title">
        <div class="header-image">
          <img src="@/assets/img/introduce/bg_grey.png" alt="" />
          <div class="right">
            <img src="@/assets/img/introduce/logo_mix_big.png" alt="" />
          </div>
        </div>
        <div class="banner">
          <div class="banner-box">
            <img src="@/assets/img/home/logo_white.png" alt="" />
            <div class="title animate fadeInDown">Who we are</div>
            <div class="sub-title animate fadeInUp">
              AD Marketing is a Singapore-based agency founded in 2020. Our core
              team is composed of executives from the industry’s most innovative
              projects spanning CEX, DEX, wallets, NFT, and media, giving AD
              Marketing extensive expertise for results-driven marketing and
              media strategy. We see our clients as partners, and our mission is
              to guide them to achieving success and growth in the global market
              with ‘Marketing Strategy’ as the core of our expertise.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.introduce {
  width: 100%;
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .home-title {
    width: 100%;
    height: calc(100vh);
    background: rgba(245, 247, 252, 0.4);
    position: relative;
    .header-image {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 27.4%);
      z-index: 0;
      height: calc(100vh);
      img {
        width: 100%;
        height: calc(100vh);
      }
      .right {
        position: absolute;
        top: 0;
        right: -282px;
        height: 100vh;
        display: flex;
        align-items: center;
        img {
          width: 484px;
          height: auto;
        }
      }
    }
    .banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
    }
    .banner-box {
      width: 72.6%;
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
        height: auto;
        position: absolute;
        top: 28px;
        left: 33px;
      }
      .title {
        width: 60.1%;
        margin-top: 36px;
        font-family: Outfit;
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 60px;
        text-transform: capitalize;
        color: #121416;
      }
      .sub-title {
        margin-top: 80px;
        font-family: Outfit-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 149%;
        /* or 30px */
        color: #333333;
        width: 60.1%;
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1280px) {
  .introduce {
    .home-title {
      .header-image {
        .right {
          position: absolute;
          top: 0;
          right: -209px;
          height: 100vh;
          display: flex;
          align-items: center;
          img {
            width: 360px;
            height: auto;
          }
        }
      }
      .banner-box {
        .title {
          font-size: 56px;
        }
        .sub-title {
          margin-top: 42px;
          font-size: 18px;
          line-height: 169%;
        }
      }
    }
  }
}
@media screen and (max-width: 799px) {
  .introduce {
    .home-title {
      .header-image {
        width: calc(100vw - 155px);
        .right {
          position: absolute;
          top: 0;
          right: -155px;
          height: calc(100vh - 40px);
          display: flex;
          align-items: flex-end;
          img {
            width: 268px;
            height: auto;
          }
        }
      }
      .banner-box {
        width: 100%;
        display: block;
        padding-left: 12px;
        box-sizing: border-box;
        height: calc(100vh - 40px);
        img {
          display: none;
        }
        .title {
          font-size: 32px;
          line-height: 48px;
        }
        .sub-title {
          margin-top: 32px;
          font-size: 18px;
          line-height: 149%;
          width: 100%;
          padding-right: 36px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>