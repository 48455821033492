<template>
  <div>
    <div class="solgan">
      <div class="solgan-left">
        <img src="@/assets/img/home/logo_white.png" alt="" />
      </div>
      <div class="solgan-right">
        <div class="desc-box">
          <div class="title animate rollIn">Our Services</div>
          <div class="sub-title animate rollIn">Branding</div>
          <div class="desc-dark animate rollIn">
            Brand Strategy & Expanding Brand Influence
          </div>
          <div class="desc animate rollIn">
            Our team offers expertise in premier brand content and helps you
            target the right channels in the right voice at the most meaningful
            moments for maximum impact.
          </div>
          <div class="desc-dark animate rollIn">Professional media writers</div>
          <div class="desc animate rollIn">
            100+ media channels for rapid distribution<br />
            Global coverage ranging from multilingual crypto media, traditional
            fintech, and more 100+ industry-wide third-party BD management for
            quick and efficient brand awareness<br />
            Strategy and execution for professional advertising and traffic
          </div>
        </div>
      </div>
      <img class="order" src="@/assets/img/service/four.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.solgan {
  width: 100%;
  height: calc(100vh);
  position: relative;
  overflow: auto;
  &-left {
    width: 7.15%;
    height: 100vh;
    background: #121416;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 40px;
      height: auto;
      margin: 28px 0;
    }
  }
  &-right {
    margin-left: 10.4%;
    height: 100vh;
    padding-top: 80px;
    box-sizing: border-box;
    overflow: auto;
    .desc-box {
      width: 54%;
      .title {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        text-transform: capitalize;
        color: #121416;
      }
      .sub-title {
        margin-top: 96px;
        font-family: "Outfit-Medium";
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 33px;
        color: #333333;
      }
      .desc {
        margin-top: 36px;
        font-family: "Outfit-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: #666666;
      }
      .desc-dark {
        margin-top: 36px;
        font-family: "Outfit-SemiBold";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #333333;
      }
    }
  }

  .order {
    height: calc(100vh - 0px);
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .solgan {
    &-right {
      margin-left: 10.4%;
      height: 100vh;
      padding-top: 80px;
      box-sizing: border-box;
      overflow: auto;
      .desc-box {
        width: 70%;
        .title {
          font-family: "Outfit";
          font-style: normal;
          font-weight: 700;
          font-size: 54px;
          line-height: 60px;
          text-transform: capitalize;
          color: #121416;
        }
        .sub-title {
          margin-top: 64px;
          font-family: "Outfit-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 33px;
          color: #333333;
        }
        .desc {
          margin-top: 24px;
          font-family: "Outfit-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 140%;
          color: #666666;
        }
        .desc-dark {
          margin-top: 24px;
          font-family: "Outfit-SemiBold";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 140%;
          color: #333333;
        }
      }
    }

    .order {
      height: calc(100vh - 0px);
      width: auto;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
@media screen and (max-width: 799px) {
  .solgan {
    &-left {
      display: none;
    }
    &-right {
      height: 100vh;
      padding: 36px 36px 0 12px;
      box-sizing: border-box;
      overflow: auto;
      margin-left: 0;
      .desc-box {
        width: 100%;
        .title {
          font-size: 32px;
          line-height: 48px;
        }
        .sub-title {
          margin-top: 32px;
          font-size: 18px;
          line-height: 23px;
        }
        .desc {
          font-size: 14px;
        }
        .desc-dark {
          font-size: 14px;
        }
      }
    }

    .order {
      height: 440px;
      width: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      top: auto;
    }
  }
}
</style>