import { routeFromNamespace } from "./tools";
import { namespace } from "../../js/namespace";

import Index from "../../views/index/Index"
import Home from "../../views/home/Home"


const hasLanUri = false
let arr = [].concat([
    routeFromNamespace(namespace.PAGE.INDEX, Index, hasLanUri),
])

arr[0].children = [].concat([
    routeFromNamespace(namespace.PAGE.HOME, Home, hasLanUri),
])

export default arr