import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'

Vue.config.productionTip = false

import VueI18n from 'vue-i18n'
import LangEN from './js/i18n/en'
import LangZH from './js/i18n/zh'
import { GetLang } from "./js/common/localStorage"

import "@/assets/styles/index.scss";
import "@/assets/styles/font/font.css";

import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// import wow from 'wowjs'

import animated from 'animate.css'

// Vue.prototype.$wow = wow
import '@/assets/font/font.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )

Vue.use(VueI18n).use(ElementUI).use(animated)
Vue.prototype.$axios = axios;
const i18n = new VueI18n({
    locale: GetLang('en'),
    messages: {
        'en': LangEN,
        'zh': LangZH,
    }
})
window.$I18n = i18n

new Vue({
    router,
    store,
    i18n,
    axios,
    render: h => h(App)
}).$mount('#app')