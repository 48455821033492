<template>
  <div>
    <div class="solgan">
      <div class="solgan-left">
        <img src="@/assets/img/home/logo_white.png" alt="" />
      </div>
      <div class="solgan-right">
        <div class="name-box">
          <div class="name-box-left">
            <div class="title animate fadeInUp">AD <br />Marketing</div>
            <div class="sub-title animate fadeInUp">
              Customized Support For<br />
              Accelerated Growth and Success
            </div>
          </div>
          <div class="name-box-right">
            <img src="@/assets/img/home/logo_black_big.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
.solgan {
  width: 100%;
  height: calc(100vh);
  position: relative;
  overflow: auto;
  &-left {
    width: 7.15%;
    height: 100vh;
    background: #121416;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 40px;
      height: auto;
      margin: 28px 0;
    }
  }
  &-right {
    margin-left: 7.15%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh);
    // padding-top: 124px;
    // box-sizing: border-box;
    overflow: auto;
    width: calc(100vw - 7.15%);
    .name-box {
      display: flex;
      justify-content: center;
      &-left {
        width: 54.6%;
        .title {
          margin-top: 36px;
          font-family: "Outfit";
          font-style: normal;
          font-weight: 700;
          font-size: 64px;
          line-height: 60px;
          text-transform: capitalize;
          color: #121416;
        }
        .sub-title {
          margin-top: 74px;
          font-family: "Outfit";
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          line-height: 43px;
          // text-transform: uppercase;
          color: #999999;
        }
      }
      &-right {
        height: 100%;
        width: 36.4%;
        img {
          width: 100%;
          height: auto;
          max-width: 484px;
        }
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1280px) {
  .solgan {
    &-right {
      .name-box {
        &-left {
          .title {
            font-size: 56px;
          }
          .sub-title {
            font-size: 24px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 799px) {
  .solgan {
    &-left {
      display: none;
    }
    &-right {
      margin-left: 12px;
      width: calc(100vw - 12px);
      height: calc(100vh - 40px);
      .name-box {
        width: 100%;
        height: calc(100vh - 40px);
        flex-direction: column;
        align-items: flex-end;
        &-left {
          width: 100%;
          .title {
            font-size: 36px;
            line-height: 36px;
          }
          .sub-title {
            font-size: 18px;
            line-height: 23px;
            margin-top: 32px;
          }
        }
        &-right {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          img {
            width: 71.4%;
            height: auto;
            max-width: 268px;
          }
        }
      }
    }
  }
}
</style>