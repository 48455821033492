<template>
  <div>
    <div class="solgan">
      <div class="solgan-left">
        <img src="@/assets/img/home/logo_white.png" alt="" />
      </div>
      <div class="solgan-right">
        <div class="desc-box">
          <div class="title animate backInRight">Our Services</div>
          <div class="sub-title animate backInRight">SEO Marketing</div>
          <div class="desc animate backInRight">
            Apply SEO so that the project’s official blog setting, and
            operation, promotional posts, and articles are exposed to the top
            level.
          </div>
          <div class="picture-list">
            <div
              v-for="(item, index) in imgList"
              :key="index"
              @mouseover="moveSolve(index)"
              @mouseout="moveOut(index)"
              class="picture-box"
            >
              <img
                :src="item.imgActive"
                alt=""
                v-if="activeIndex === index && show"
              />
              <img :src="item.img" alt="" v-else />
            </div>
          </div>
        </div>
      </div>
      <img class="order" src="@/assets/img/service/five.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      activeIndex: -1,
      imgList: [
        {
          img: require("@/assets/img/service/logo_google_1.png"),
          imgActive: require("@/assets/img/service/logo_google_2.png"),
        },
        {
          img: require("@/assets/img/service/logo_bing_1.png"),
          imgActive: require("@/assets/img/service/logo_bing_2.png"),
        },
        {
          img: require("@/assets/img/service/logo_yahoo_1.png"),
          imgActive: require("@/assets/img/service/logo_yahoo_2.png"),
        },
        {
          img: require("@/assets/img/service/logo_naver_1.png"),
          imgActive: require("@/assets/img/service/logo_naver_2.png"),
        },
      ],
    };
  },
  methods: {
    moveSolve(index) {
      this.activeIndex = index;
      this.show = true;
    },
    moveOut(index) {
      this.show = false;
      this.activeIndex = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.solgan {
  width: 100%;
  height: calc(100vh);
  position: relative;
  overflow: auto;
  &-left {
    width: 7.15%;
    height: 100vh;
    background: #121416;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 40px;
      height: auto;
      margin: 28px 0;
    }
  }
  &-right {
    margin-left: 150px;
    height: 100vh;
    padding-top: 80px;
    box-sizing: border-box;
    overflow: auto;
    .desc-box {
      width: 54%;
      .title {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        text-transform: capitalize;
        color: #121416;
      }
      .sub-title {
        margin-top: 116px;
        font-family: "Outfit-Medium";
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 33px;
        color: #333333;
      }
      .desc {
        margin-top: 36px;
        font-family: "Outfit-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: #666666;
      }
      .desc-dark {
        margin-top: 36px;
        font-family: "Outfit-SemiBold";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #333333;
      }
      .picture-list {
        display: flex;
        margin-top: 99px;
        .picture-box {
          width: 160px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 24px;
          border: 1px solid #dedede;
          border-radius: 2px;
          box-sizing: border-box;
          img {
            width: 100px;
            height: auto;
          }
        }
      }
    }
  }

  .order {
    height: calc(100vh - 0px);
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 799px) {
  .solgan {
    &-left {
      display: none;
    }
    &-right {
      height: 100vh;
      padding: 36px 36px 0 12px;
      box-sizing: border-box;
      overflow: auto;
      margin-left: 0;
      .desc-box {
        width: 100%;
        .title {
          font-size: 32px;
          line-height: 48px;
        }
        .sub-title {
          margin-top: 32px;
          font-size: 18px;
          line-height: 23px;
        }
        .desc {
          font-size: 14px;
        }
        .desc-dark {
          font-size: 14px;
        }
        .picture-list {
          margin-top: 32px;
          flex-direction: column;
          .picture-box {
            margin-top: 16px;
            width: 128px;
            height: 48px;
            img {
              width: 80px;
              height: auto;
            }
          }
        }
      }
    }

    .order {
      height: 440px;
      width: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      top: auto;
    }
  }
}
</style>