const langUriPrefix = "/:lang "

let namespace = {
    STORE: {},
    PAGE: {
        INDEX: { name: 'index', uri: '/', redirect: '/home' },
        HOME: { name: 'home', uri: '/home' },
        ABOUT: { name: 'about', uri: '/about' },
        SAAS: { name: 'saas', uri: '/saas' },
        METAVERSE: { name: 'metaverse', uri: '/metaverse' },
        PRIVACYAGREEMENT: { name: 'privacyagreement', uri: '/privacyagreement' },
        USERAGREEMENT: { name: 'useragreement', uri: '/useragreement' },
    }
}


export {
    namespace
}