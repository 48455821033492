<template>
  <div>
    <div class="introduce">
      <div class="introduce-left">
        <img src="@/assets/img/home/logo_black.png" alt="" />
        <div class="desc-box">
          <div class="title animate backInLeft">Search for more</div>
          <div class="desc-dark animate backInLeft">Business Development</div>
          <div class="desc animate backInLeft">
            Our network can help projects establish partnerships and meaningful
            marketing collaborations
          </div>
          <div class="desc-dark animate backInLeft">Listing Support</div>
          <div class="desc animate backInLeft">
            We refer quality projects to top-tier exchanges
          </div>
          <div class="desc-dark animate backInLeft">Financial Advisory</div>
          <div class="desc animate backInLeft">
            Our global resource network can help quality projects to rapidly and
            efficiently connect with investors and financial institutions
          </div>
          <div class="desc-dark animate backInLeft">Trading Support</div>
          <div class="desc animate backInLeft">
            Our team of experienced professionals provide strategic consultation
            for trading, liquidity supply, quantitative trading, token economy
            optimization, and more.
          </div>
          <div class="desc-dark animate backInLeft">Others</div>
          <div class="desc animate backInLeft">
            Our network also offers additional support for project needs
            including legal advisory, security audits, and talent.
          </div>
        </div>
      </div>
      <div class="introduce-right">
        <div class="img-box">
          <img class="skew" src="@/assets/img/more/skew.png" alt="" />
          <div class="top-grade">
            <img class="ad" src="@/assets/img/more/AD_Marketing.png" alt="" />
            <img class="logo" src="@/assets/img/more/logo_AD.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.introduce {
  width: 100%;
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  &-left {
    width: 54%;
    height: 100vh;
    position: relative;
    padding-left: 40px;
    overflow: auto;
    img {
      position: absolute;
      width: 40px;
      height: auto;
      top: 28px;
      left: 40px;
    }
    .title {
      margin: 106px 0 66px;
      font-family: Outfit;
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 60px;
      text-transform: capitalize;
      color: #121416;
    }
    .desc-dark {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #333333;
    }
    .desc {
      font-family: "Outfit-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      margin: 10px 0 36px;
      color: #666666;
    }
  }
  &-right {
    width: 46%;
    height: 100vh;
    padding-left: 42px;
    box-sizing: border-box;
    .img-box {
      width: 100%;
      position: relative;
      height: 100vh;
      .skew {
        width: 36.5%;
        height: 100vh;
        position: absolute;
        left: 14.78%;
        top: 0;
        z-index: -1;
        max-width: 240px;
      }
      .top-grade {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        height: 100vh;
        .logo {
          width: 66.15%;
          height: auto;
          margin-top: 75px;
          max-width: 434px;
        }
        .ad {
          width: 42.07%;
          height: auto;
          margin-left: 11.58%;
          max-width: 276px;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .introduce {
    &-left {
      width: 60%;
      .title {
        margin: 106px 0 40px;
        font-size: 56px;
      }
      .desc-dark {
        font-size: 18px;
      }
      .desc {
        font-size: 15px;
      }
    }
    &-right {
      width: 40%;
    }
  }
}
@media screen and (max-width: 799px) {
  .introduce {
    &-left {
      width: 100%;
      padding: 0 36px 0 12px;
      img {
        display: none;
      }
      .title {
        margin: 36px 0 32px;
        font-size: 32px;
        line-height: 48px;
      }
      .desc-dark {
        font-size: 16px;
        line-height: 20px;
      }
      .desc {
        font-size: 14px;
        line-height: 149%;
      }
    }
    &-right {
      position: absolute;
      right: 0;
      width: 247px;
      padding-left: 0;
      .img-box {
        width: 247px;
        .skew {
          width: 136px;
          left: 55px;
        }
        .top-grade {
          width: 247px;
          display: flex;
          height: calc(100vh - 40px);
          justify-content: flex-end;
          padding-bottom: 52px;
          box-sizing: border-box;
          .ad {
            display: none;
          }
          .logo {
            width: 247px;
            position: absolute;
            z-index: -1;
          }
        }
      }
    }
  }
}
</style>