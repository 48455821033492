<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}
#app {
  font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
  color: transparent;
}
a {
  text-decoration: none;
}
/* swiper 动画 */
.swiper-slide-active .animate {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

/* 这里面更改动画名称 */
.swiper-slide-active .fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
/* 这里面更改动画名称 */
.swiper-slide-active .fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.swiper-slide-active .fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.swiper-slide-active .fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.swiper-slide-active .backInRight {
  -webkit-animation-name: backInRight;
  animation-name: backInRight;
}
.swiper-slide-active .backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft;
}
.swiper-slide-active .zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
.swiper-slide-active .rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}
.swiper-slide-active .lightSpeedInLeft {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
}
</style>
