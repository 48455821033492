<template>
  <div class="sidebar">
    <div class="nav">
      <div
        :class="activeMenu == item.id ? 'nav-box-selected' : 'nav-box'"
        v-for="(item, index) in navList"
        :key="index"
        @click="navTo(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="nav-mobile">
      <img src="@/assets/img/home/logo_white.png" alt="" />
      <img
        class="menu"
        src="@/assets/nav/menu-icon.png"
        alt=""
        @click="openMenu()"
      />
    </div>
    <div class="menu-box" v-show="menuShow">
      <div class="menu-list">
        <img src="@/assets/nav/close.png" alt="" @click="openMenu()" />
        <div
          class="menu-name"
          :style="{ color: menuIndex == item.id ? '#000000' : '#333333' }"
          v-for="item in navList"
          :key="item.id"
          @click="navTo(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <swiper
      :options="swiperOption"
      @slideChangeTransitionEnd="slideChangeTransitionEnd"
      ref="mySwiper"
      class="swiper-container"
    >
      <swiper-slide> <Name></Name></swiper-slide>
      <swiper-slide> <Introduce></Introduce></swiper-slide>
      <swiper-slide> <ServicesOne></ServicesOne></swiper-slide>
      <swiper-slide> <ServicesTwo></ServicesTwo></swiper-slide>
      <swiper-slide> <ServicesThree></ServicesThree></swiper-slide>
      <swiper-slide> <ServicesFour></ServicesFour></swiper-slide>
      <swiper-slide> <ServicesFive></ServicesFive></swiper-slide>
      <swiper-slide> <More></More></swiper-slide>
      <swiper-slide> <Partner></Partner></swiper-slide>
      <swiper-slide> <Contact></Contact></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import jquery from "jquery"
// import "swiper/css/swiper.min.css"; // 注意这里的引入
import Name from "./modules/Name.vue";
import Introduce from "./modules/Introduce.vue";
import ServicesOne from "./modules/ServicesOne.vue";
import ServicesTwo from "./modules/ServicesTwo.vue";
import ServicesThree from "./modules/ServicesThree.vue";
import ServicesFour from "./modules/ServicesFour.vue";
import ServicesFive from "./modules/ServicesFive.vue";
import Contact from "./modules/Contact.vue";
import More from "./modules/More.vue";
import Partner from "./modules/Partner.vue";
export default {
  components: {
    Name,
    Introduce,
    ServicesOne,
    ServicesTwo,
    ServicesThree,
    ServicesFour,
    ServicesFive,
    Contact,
    More,
    Partner,
  },
  data() {
    return {
      activeCircle: 0,
      activeMenu: 0,
      menuIndex: 0,
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        loop: true,
        freeMode: false,
        direction: "vertical",
        mousewheel: true,
        // mousewheelControl: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
      },
      navList: [
        {
          name: "AD MARKETING",
          id: 0,
        },
        {
          name: " ABOUT",
          id: 1,
        },
        {
          name: "OUR SERVICES",
          id: 2,
        },
        {
          name: "SEARCH FOR MORE",
          id: 7,
        },
        {
          name: "PARTNER",
          id: 8,
        },
        {
          name: "CONTACT",
          id: 9,
        },
      ],
      menuShow: false,
    };
  },
  methods: {
    navTo(idx) {
      let pages = jquery(".swiper-container span.swiper-pagination-bullet")
      // console.log(idx)
      pages.get(idx).click()
      this.menuShow = false

    },
    slideChangeTransitionEnd() {
      if (
        this.$refs.mySwiper.swiper.realIndex == 2 ||
        this.$refs.mySwiper.swiper.realIndex == 3 ||
        this.$refs.mySwiper.swiper.realIndex == 4 ||
        this.$refs.mySwiper.swiper.realIndex == 5 ||
        this.$refs.mySwiper.swiper.realIndex == 6
      ) {
        this.activeMenu = 2;
      } else {
        this.activeMenu = this.$refs.mySwiper.swiper.realIndex;
      }
      // console.log(
      //   this.$refs.mySwiper.swiper.realIndex,
      //   " this.$refs.mySwiper.swiper.realIndex this.$refs.mySwiper.swiper.realIndex"
      // );
    },
    openMenu() {
      this.menuShow = !this.menuShow;
    },
  },
  mounted() {
    this.mySwiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      // 设置分页器
      pagination: {
        el: ".swiper-pagination",
        // 设置点击可切换
        clickable: true,
      },
      direction: "vertical",
      mousewheel: true,
      effect: "fade",
      loop: false,
    });
  },
};
</script>
<style lang="scss" scoped>
/deep/.swiper-wrapper {
  -webkit-transition-timing-function: linear; /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}
.swiper-container {
  height: 100vh;
}
.nav {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 40px;
  right: 80px;
  .nav-box {
    font-family: "PingFang HK";
    cursor: pointer;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #333333;
    margin-right: 32px;
    z-index: 99999;
  }
  .nav-box-selected {
    font-family: "PingFang HK";
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #000000;
    margin-right: 32px;
    z-index: 99999;
  }
}
.nav-mobile {
  display: none;
}
:deep().swiper-pagination-bullet-active {
  background: rgba(0, 0, 0, 1);
}
.circle-box {
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 6px;
  height: 6px;
  background: rgba(51, 51, 51, 0.5);
  opacity: 0.3;
  border-radius: 50%;
  margin-bottom: 5px;
}
.circle-selected {
  width: 12px;
  height: 12px;
  background: rgba(0, 0, 0, 1);
  border-radius: 50%;
  margin-bottom: 5px;
}
@media screen and (max-width: 799px) {
  .swiper-container {
    height: calc(100vh - 40px);
  }
  .nav {
    display: none;
  }
  .nav-mobile {
    width: 100%;
    height: 40px;
    background: #121416;
    display: flex;
    align-items: center;
    img {
      height: 24px;
      width: auto;
      margin-left: 12px;
    }
  }
  .menu {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .menu-box {
    width: 100%;
    height: calc(100vh);
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999999999999;
  }
  .menu-list {
    background-color: #fff;
    margin-left: 75px;
    height: 100vh;
    position: relative;
    padding: 72px 0 0 32px;
    box-sizing: border-box;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 8px;
      right: 12px;
    }
    .menu-name {
      font-family: "PingFang HK";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      color: #333333;
      margin-bottom: 32px;
    }
  }
}
</style>